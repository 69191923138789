body {
  overflow-y: scroll;
  margin: 0;
  color: black;
  /* line-height: 1.8 !important; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Minion Pro', 'Minion', 'Times New Roman', 'Georgia' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  font-family: 'Minion Pro', 'Minion', 'Times New Roman';
  -webkit-font-smoothing: antialiased;
}


.textProfil {
  font-weight:100 !important;
  font-size: 22px !important;
  line-height: 2rem !important;
}
.textProfilMob {
  font-weight:100 !important;
  font-size: 15px !important;
  line-height: 1.5rem !important;
}
h1 {
  /* font-weight:900 !important; */
  font-size: 32px !important;
}
h2 {
  font-weight:100 !important;
  font-size: 22px !important;
  line-height: 2rem !important;
}
h3 {
  font-weight:100 !important;
  font-size: 22px !important;
  line-height: 2rem !important;
}

h5 {
  font-size: 18px !important;
  text-transform: Capitalize !important;
  font-weight: bold !important;
  /* font-weight: bold !important; */
  /* padding:0 !important; */
  margin:0 !important;
  padding-left:25px !important;
  padding-right:25px !important;
  /* line-height: 16px; */
}
h6 {
  font-family: 'Helvetica Neue','Helvetica','Arial' !important;
  /* padding:0; */
  font-size: 16px !important;
  /* font-weight: bold !important; */
  font-weight: 400 !important;
  margin-bottom:16px !important;
  padding-left:25px !important;
  padding-right:25px !important;
  /* line-height: 16px; */
}
.division {
  height: 1px;
  width: 100%;
  background-color: rgb(170, 170, 170);
  margin-bottom: 15px;
}

.info{
  color:white;
}
.info:hover{
  color:white;
}

.logo {
  top:58px;
  left:76px;
  position:absolute !important;
  z-index:10;
}
.logoMob {
  top:32px;
  left:50px;
  max-width:200px;
  position:absolute !important;
  z-index:12;
}


.logoSticky {
  top:0px;
  left:80px;
  position:sticky !important;
  position: -webkit-sticky; /* for Safari */
  float:left;
  margin: 0px 0px 50px -450px;
  width: 0px;
  z-index:5; 
  /* background-color: red; */
}
.logoPusher {
  /* position: relative; */
  height:90vh;
  width: 0px;
  /* background-color: blue; */
  z-index:5;
}
.logoStickyImg {
  width: 350px;
  height: 50px;
  cursor: pointer;
  position: sticky;
  top: 90vh;
  z-index:45;
}


.buttonAbsoluteKontakt {
  top:54px;
  right:61px;
  position:absolute;
}
.buttonAbsolute {
  top:70px;
  right:76px;
  position:absolute;
}
.buttonAbsoluteMob {
  top:36px;
  right:16px;
  position:absolute;
}
.buttonSticky {
  top:54px;
  right:76px;
  position:sticky !important;
  position: -webkit-sticky; /* for Safari */
  float:right;
  margin: 50px 0px 50px -220px;
  width: 220px;
}
.buttonStickyMob {
  top:16px;
  right:16px;
  position:sticky !important;
  position: -webkit-sticky; /* for Safari */
  float:right;
  margin: 0px 0px 50px -220px;
  width: 220px;
}

.offerBtn {
  font-size: 22px;
  font-weight: 900px;
  /* position:absolute !important; */
  /* text-align: right; */
  padding: 10px 25px 10px 25px;
  vertical-align: middle;
  display: inline-block;
  background-color: #000;
  color: #fff;
  z-index:10;
}
.offerBtn:hover, .offerBtnMob:hover {
 text-decoration: none;
 color:#fff;
 background-color: #212529;
}
.offerBtnMob {
  cursor: pointer;
  font-size: 22px;
  font-weight: 900px;
  /* position:absolute !important; */
  /* text-align: right; */
  padding: 10px 25px 10px 25px;
  vertical-align: middle;
  display: inline-block;
  background-color: #000;
  color: #fff;
  z-index:10;
}
.sendBtn {
  width: 210px;
  top:54px;
  right:61px;
  position:absolute;
  font-size: 22px !important;
  z-index:14;
  font-weight: 900 !important;
  /* display: inline-block; */
}
.btnText {
  height: 30px;
  line-height: 30px;
  font-weight: bold;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif; */
}

.language {
  position: absolute;
  top:79px;
  right:70px;
  border: none;
  background: none;
  color: #555;
  font-size:20px;
  text-transform: uppercase;
  z-index:11;
}
.language:hover {
  color: black;
  text-decoration:none;
}
.languageMob {
  position: absolute;
  top:45px;
  right:40px;
  border: none;
  background: none;
  color: #555;
  font-size:18px;
  text-transform: uppercase;
  z-index:11;
}
.languageMob:hover {
  color: black;
  text-decoration:none;
}

.plusSymbol {
  font-size: 50px;
  line-height: 50px;
  /* padding: 0px 0px 10px 0px; */
  z-index:63
}
.plusBox{
  cursor: pointer;
  /* margin:45px auto -115px;  */
  width:50px;
  height:50px;
  /* text-align: left; */
  left: 40px;
  /* top:40px; */
  /* float:left; */
  /* font-size:50px; */
  font-weight:bold;
  background-color:black;
  color:white;
  padding: 0px 10px 20px 10px;
  z-index:62 !important;
}
.infoBox{
  cursor: pointer;
  /* display:block; */
  text-align: left;
  /* margin: 45px auto -115px;
  left: 50px;
  top:50px;
  float:left; */
  width: auto;
  height: auto;
  color: white;
  background-color:black;
  font-weight: bold;
  line-height: 16px;
  font-size: 14px;
  padding: 12px 10px 20px 12px;
  margin-right:-30px;
  z-index:62;
}

.arrowDown {
  /* background-color: transparent !important; */
  z-index: 30;
  position: absolute;
  left: 70px;
  bottom: 60px;
  width: 3vmax;
  height: 3vmax;
  cursor: pointer;
  /* opacity: 0.4; */
}
.arrowDown img {
  /* background-color: transparent !important; */
  position: absolute;
  width: 100%;
  /* height: 50%; */
  bottom: 0px;
  left:0;
}

.sticky{
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  /* margin: 45px auto -50px;
  height: 5px; */
  margin: 50px -220px 70px;
  width: 220px;
  color: white;
  text-align: left;
  left: 50px;
  top:50px;
  float:left;
  /* display: inline-block; */
  /* background-color:black; */
  /* padding:20px; */
  /* padding-bottom: 1em;
  z-index: 66;
  text-align: left;
  display: inline-block; */
}
.stickyMob{
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  margin: 0px -220px 16px;
  width: 220px;
  /* margin: 0px auto -50px; */
  /* height: 25px;
  width: 25px; */
  color: white;
  text-align: left;
  left:16px;
  /* padding-top:20px; */
  top:16px;
  float:left;
}
.goTop{
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  bottom:0px;
  /* margin: 0px auto 0px; */
  width:40px;
  height: 50px;
  cursor: pointer;
  /* width:45px; */
  height:45px;
  right: 50px;
  /* top:100%; */
  float: right;
  text-align:right;
  font-size:40px;
  font-weight:bold;
  background-color:black;
  color:white;
  /* margin-top:calc(100% + 200px); */
  padding: 0px 10px 10px 10px;
  /* margin-right:-100px; */
}
.goTopMob{
  visibility: hidden;
}
.goContainer{
  position:sticky;
height: calc(100% + 100px);
width: 0;
height: 0;
top:0;
bottom:0;
}



/* .logoSmall {
  top:50px;
  height:50px;
  width:190px;
  position:fixed !important;
} */

img {
  pointer-events: none;
}

.nav {
  font-size: 2vw;
  font-weight: 900;
  /* font-family: 'Minion Pro'; */
  text-transform: capitalize;
  z-index: 25;
}

.navLink {
  color: #212529;
  padding-left: 50px;
  transition: 0.3s;
  /* color: white; */
  text-shadow: -1px -1px 0 #dedede, 1px -1px 0 #dedede, -1px 1px 0 #dedede, 1px 1px 0 #dedede;
}
.mobMenu {
  margin: 16px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  /* background-color:#dedede; */
  /* background: url(bg.jpg); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #000;
  text-align: left;
  z-index: 200;
}

.navMob {
  font-size: 40px;
  font-weight: 900;
  padding-left: 30px;
  /* height: 100vw !important; */
  /* position: fixed; */
  
  /* margin: auto; */
  /* padding: 0px 0px 0px 30px; */
  /* background-color: #888; */
  /* font-family: 'Minion Pro'; */
  /* text-align: center; */
}
.navLinkMob {
  display: block !important;
  padding-left: 20px;
  padding: 5px;
  color: #fff;
  transition: 0.3s;
  z-index: 200;

}

.link {
  color: #212529;
  transition: 0.3s;
}
.linkUnderlined {
  color: #212529;
  text-decoration: underline;
}
.navLink:hover, .link:hover, .navLinkMob:hover {
  color: rgb(142, 142, 147);
  text-decoration: none;
}

.bg {
  margin: 16px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  background-color:#dedede;
  /* background: url(bg.jpg); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* height: 100%; */
}
.bgMob {
  margin-top: 16px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  background-color:#dedede;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

}
.form-container-mob {
/* min-height: 100%; */
margin-top: 16px;
/* height:auto !important; */
/* overflow:visible !important; */
background-color:#dedede;
padding: 100px 16px 32px 16px ;
margin-bottom: 16px;
}
.text {
  text-align: left;
  font-size: 24px;
}
.textMob {
  text-align: left;
  padding: 0 !important;
}
.textBig {
  font-size: 30px; 
  font-weight: 200px;
  /* font-family: 'Minion Pro'; */
  text-align: left;
}
.textBigMob {
  font-size: 24px; 
  font-weight: 200px;
  /* font-family: 'Minion Pro'; */
  text-align: center;
}
p {
  max-width: 800px;
}
.portrait {
  z-index: 10 !important;
}
.textFront {
  z-index: 20 !important; 
}

code {
  font-family: 'Times New Roman', 'Minion Pro', 'Minion', 'Times New Roman', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.carousel-indicators {
  visibility: hidden;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
  visibility: hidden;
}



/* .logo1 {
  line-height:40px;
  font-size: 56px;
  font-weight: 600;
}
.logo2 {
  line-height:40px;
  font-size: 56px;
  font-weight: 200;
}

.subtitle {
  padding: 50px 20px 50px 20px;
  text-align: center;
} */

.center {
  /* margin: auto; */
  margin: 0;
  position: absolute;
  width:100%;
  top: 53%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.partner {
  /* margin: auto; */
  margin: 0;
  position: absolute;
  width:100%;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.contact {
  /* margin: auto; */
  margin: 0;
  position: absolute;
  width:100%;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.hidden {
  visibility: hidden;
}

/* LOADER */

.loader {
  position: absolute;
  text-align: center;
  top: 50%;
  border-bottom: 2px;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  animation: fadeOut ease 2s;
}

/* .main {
  bottom: 0;
  z-index: 96;
} */

.loader--hide {
  opacity: 0;
}

@keyframes fadeIn {
  0% {opacity:0;}
  50% {opacity:0;}
  100% {opacity:1;}
  }

@keyframes fadeOut {
  0% {opacity:1;}
  50% {opacity:1;}
  100% {opacity:0;}
  }


  /* FORMS */
  .form-container  {
    max-width: 1600px;
    padding: 30px;
    /* background-color: #fff; */
  }
  .form-group {
    margin-bottom: 0;
}
  .form-control {
    font-size: 24px !important;
    color: #212529 !important;
  }
  .form-label {
    font-size: 24px;
    font-weight: bold;
  }
  label {
    /* padding: 5px !important; */
    /* margin: 0 !important; */
    font-size: 24px;
    /* line-height: 30px !important; */
    /* font-weight: bold; */
  }
  legend {
    font-size: 24px !important;
    font-style: italic;
  }
  .line {
    margin-top: 14px;
  }
  .form-group {
    /* margin-bottom: 0px !important; */
    /* margin-top: 5px !important; */
}
.form-check-label {
  cursor:pointer;
}
  .label-underline {
    background-color: #212529;
    height: 1.5px;
    width: auto;
    /* margin-bottom: 16px; */
    /* border-bottom-width: 2px !important; */

  }

  input, textarea {
    /* padding:0 !important; */
    border-radius: 0 !important;
    border-color: #212529 !important;
    border-width: 0px 0px 1px 0px !important;
    background-color: transparent !important;
  }
  .noborder {
    border-width: 0 !important;
    font-size: 22px !important;
  }
  textarea {
    font-size: 24px !important;
    line-height: 26px !important;
    padding: 15px !important;
    /* padding: 10px 10px 30px 10px !important; */
    width: 100%;
  }
  hr {
    /* border-width: 1px !important; */
    margin: 5px !important;
    border-color: #212529 !important;
  }
  fieldset {
    margin: 0px !important;
    /* border: #000 !important;
    border-width: 3px !important;
    border-color: #212529 !important;
    background-color: yellow; */
  }


  .form-check-input {
    /* border-radius: 100% !important; */
    /* transform: scale(1.8);
    -webkit-transform: scale(1.8); */
  }
  .form-check-input[type="checkbox"] {
    /* border-radius: 10em !important; */
}
  input[name="gridRadios"] {
    /* accent-color: #444; */
  }
  input[type="checkbox"] {
    width: 35px !important;
    height: 35px !important;
    right:40px;
    margin: 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: 0.5px solid #8f8f8f;
    box-shadow: none;
    font-size: 2em;
    text-align: center;
    line-height: 1em;
    
    accent-color: #dedede;
    background-color: transparent !important;
    /* border-radius: 100% !important; */
  }

  input[type='checkbox']:checked:after {
    /* clip-path: circle(50% at 50% 50%);  */
    
    /* content: '✓'; */
    content: '✔';
    color: #222;
    /* background-color: #212529; */
    position: absolute;
    line-height: 2rem;
    font-size: 2rem;
    margin-right: 2px;
    margin-top: 3px;
    right: 0.05rem !important;
  }

  input[type='checkbox']:checked {
    
    /* width: 17px !important;
    height: 17px !important;
    margin: 5px;
    -webkit-appearance: auto;
    -moz-appearance: auto;
    -o-appearance: auto;
    appearance: auto; */


    /* clip-path: circle(50% at 50% 50%);  */
  }
  input[type='checkbox']:hover, input[type='checkbox']:checked:hover {
    /* accent-color: #a0a0a0; */
    cursor:pointer;
    background-color: #979ea5 !important;
  }

  .numbersCount {
    font-size: 30px;
    line-height: 1;
  }

  .btnRound {
    border-color: #8f8f8f;
    border-style: solid;
    cursor: pointer;

    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 40px;
    padding-top: 4px;
    font-weight: 600;
    line-height: 40px;
    /* outline: 5px solid rgb(176, 176, 176); */
    
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -khtml-border-radius: 50%;
    border: 1px solid #8f8f8f;


    background-color: transparent;
    transition: .2s;
  }
  /* .btnRound:hover {
    background-color: #888;
  } */
  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }






/* Remove form input glow */
textarea:focus,
textarea.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  /* border-color: black; */
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px black; */
  border-color: transparent;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.075) inset, 0 0 8px transparent;
  outline: 0 none;
}

*:hover,
*:focus,
*:active
 {
  outline: none;
  box-shadow: none !important;
  -webkit-appearance: none;
}